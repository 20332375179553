import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngICODevelopment = loadable(() => import("../components/ICODevelopment/english.js"));
const ArabicICODevelopment = loadable(() => import("../components/ICODevelopment/arabic.js"));
const ChineseICODevelopment = loadable(() => import("../components/ICODevelopment/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "image": "https://softtik.com/images/nft-collection/invest-section-12.webp",
      "name": "Top ICO Development Company",
      "description": "Softtik is a leading ICO development company reshaping startups and businesses for long-term success with result-oriented ICO development solutions.",
      "brand": "Softtik Technologies",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.7",
        "reviewCount": "122"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is ICO?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>ICO is a way to raise funds for developing any project (Game, crypto project, or any other service). An initial Coin Offering (ICO) is similar to Initial Public Offering (IPO) without regulations.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Is it reasonable to invest in an ICO?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Despite many stories of rug-pull around ICOs, We think ICOs are a good investment. There are chances that we might invest in something that will be the future of the crypto market. However, thorough research of the Project is recommended before jumping into ICOs.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How do companies make money from ICO?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Companies raise funds by selling the project tokens to early investors and traders. Companies also have a percentage of total tokens allocated for them. The initial investment comes from the token sale, but companies can trade specified tokens to gain money once the Project is developed.</p>"
        }
      }
      ]
    }
  ]
}

export class ICODevelopment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English"
    }
  };

  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    let { lang } = this.state;
    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/ico-development-services"
            title="ICO Development Company - Softtik Technologies/"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-12.webp"
            description="Softtik is a leading ICO development company reshaping startups and businesses for long-term success with result-oriented ICO development solutions."
          />
          <main>
            <div id="wrapper">
              <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicICODevelopment />
                  : lang == "China"
                    ? <ChineseICODevelopment />
                    : <EngICODevelopment />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};

export default ICODevelopment;